







































import { defineComponent, PropType, useContext, computed } from '@nuxtjs/composition-api';
import type { AttributeCollectionItem } from '@wemade-vsf/magento-api';
import { SfHeading } from '@storefront-ui/vue';
import { useImage } from '@wemade-vsf/composables';

export default defineComponent({
  name: 'AttributeCollectionItemGrid',
  props: {
    columns: {
      type: [String, Number],
      default: '3'
    },
    items: {
      type: Array as PropType<AttributeCollectionItem[]>,
      default: () => []
    },
    layout: {
      type: String,
      default: 'card'
    },
    imageHeight: {
      type: Number
    },
    imageWidth: {
      type: Number
    },
    collectionUrlKey: {
      type: String,
      default: ''
    }
  },
  components: {
    SfHeading
  },
  setup (props) {
    const { localeRoute } = useContext();
    const { imageSizes, getMagentoImage } = useImage();
    const imgHeight = props.imageHeight ? props.imageHeight : imageSizes.card?.height || 400;
    const imgWidth = props.imageWidth ? props.imageWidth : imageSizes.card?.width || 400;

    const cardItems = computed(() => props.items.map((item: AttributeCollectionItem) => {
      return {
        ...item,
        link: localeRoute({ name: 'attributeCollectionItem', params: { slug: item.url_key, collection_slug: props.collectionUrlKey ? props.collectionUrlKey : item.collection?.url_key || '' } })
      }
    }))

    return {
      imgHeight,
      imgWidth,
      getMagentoImage,
      cardItems
    }
  }
})
