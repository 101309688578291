




















































import {
  defineComponent,
  useFetch,
  useContext,
  ref,
  useRoute,
  computed
} from '@nuxtjs/composition-api';
import { useCache } from '@wemade-vsf/cache';
import { createSmoothScroll, getMetaInfo } from '@wemade-vsf/core';
import { useAttributeCollection } from '@wemade-vsf/attribute-collection';
import type { AttributeCollection, AttributeCollectionItem } from '@wemade-vsf/magento-api';
import { SfBreadcrumbs, SfHeading, SfSearchBar } from '@storefront-ui/vue';
import CmsContent from 'components/theme/Content/CmsContent.vue';
import AttributeCollectionItemGrid from 'components/attribute-collection/AttributeCollectionItemGrid.vue';

export default defineComponent({
  name: 'AttributeCollection',
  components: {
    SfBreadcrumbs,
    SfHeading,
    SfSearchBar,
    CmsContent,
    AttributeCollectionItemGrid
  },
  setup () {
    const route = useRoute()
    const { addTags } = useCache()
    const { error: nuxtError, i18n, localeRoute } = useContext()
    const { loading, error, getCollection } = useAttributeCollection();
    const attributeCollection = ref<AttributeCollection | null>(null)
    const breadcrumbs = ref<Array<{text: string, link: string}>|null>(null)

    const search = ref('')
    const itemRegister = computed<Record<string, AttributeCollectionItem[]>>(() => {
      if (attributeCollection.value?.items?.length) {
        return attributeCollection.value.items.sort((a, b) => {
          return a.title > b.title ? 1 : -1
        }).reduce((acc: Record<string, AttributeCollectionItem[]>, item) => {
          const searchTerm = search.value ? search.value.trim().toLowerCase() : ''
          let display = true
          if (searchTerm.length) {
            display = item.title && item.title.toLowerCase().startsWith(searchTerm)
          }
          if (display) {
            const letter = item.title[0].toUpperCase()
            acc[letter] = acc[letter] || []
            acc[letter].push(item)
          }
          return acc
        }, {})
      } else {
        return {}
      }
    })

    const scrollTo = (letter: string) => {
      if (process.server) return
      const section = document.getElementById(`register-${letter}`)
      if (section) {
        createSmoothScroll(document.documentElement.scrollTop || document.body.scrollTop, section.offsetTop - 80)
      }
    }

    useFetch(async () => {
      const query = {
        url_key: route.value?.params?.slug || ''
      }
      attributeCollection.value = await getCollection(query)

      if (error?.value?.getCollection || !attributeCollection.value) nuxtError({ statusCode: 404 })

      if (route.value?.params?.slug) {
        addTags([{ prefix: 'AC', value: route.value.params.slug }])
        breadcrumbs.value = [
          { text: i18n.t('Home'), link: localeRoute({ name: 'home'}).fullPath },
          { text: attributeCollection.value.title || '', link: localeRoute({ name: 'attributeCollection', params: { slug: route.value.params.slug } }).fullPath }
        ]
      }
    })

    return {
      loading,
      breadcrumbs,
      attributeCollection,
      search,
      itemRegister,
      scrollTo
    }
  },
  head () {
    return getMetaInfo(this.attributeCollection)
  }
})
